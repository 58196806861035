import React from "react";
import { Select } from "antd";

const { Option } = Select;

const CustomSelectWidget = (props) => {
  const { options, value, disabled, readonly, onChange, label, id } = props;
  const { enumOptions,onChange:formOnChnage, ...restOptions } = options;

  const handleChange = (newValue) => {
    onChange(newValue);
    formOnChnage && formOnChnage(newValue);
  }

  //console.log("hell", restOptions);

  return (
    <Select
      id={id}
      value={value}
      onChange={handleChange}
      placeholder={`Select ${label || "an option"}`}
      optionFilterProp="children"
      disabled={disabled || readonly}
      allowClear
      showSearch
      {...restOptions}
    >
      {enumOptions?.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

export default CustomSelectWidget;

// import React from "react";
// import { Select } from "antd";
// import { useAnonymous } from "../context/anonymousContext";

// const { Option } = Select;

// const CustomSelectWidget = (props) => {
//   // //console.log(props, "propss");
//   const {
//     options,
//     value,
//     onChange: onFormChanged,
//     disabled,
//     readonly,
//     label,
//   } = props;
//   const { enumOptions, onChange } = options;
//   const { status } = useAnonymous();

//   const handleChange = (value) => {
//     if (onChange) {
//       onChange(value);
//     }
//     onFormChanged(value);
//   };
//   return (
//     <Select
//       value={value}
//       showSearch
//       optionFilterProp="children"
//       filterOption={(input, option) =>
//         option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
//       }
//       onChange={handleChange}
//       label={`Select ${label}`}
//       disabled={status === "submitted" || readonly}
//     >
//       {(enumOptions || []).map((option) => (
//         <Option key={option.value} value={option.value}>
//           {option.label}
//         </Option>
//       ))}
//     </Select>
//   );
// };

// export default CustomSelectWidget;
