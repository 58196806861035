import { Button, Checkbox, Modal } from "antd";
import React, { useState } from "react";
import usePopupModal from "../hooks/popupModal";

const TermsAndConditionWidget = ({
  id,
  value,
  onChange,
  label,
  options,
  required,
  schema, // schema contains the description and URL
}) => {
  const { isOpen, openModal, closeModal } = usePopupModal();
  const [isAccepted, setIsAccepted] = useState(value || false);

  const handleCheckboxClick = (e) => {
    // If schema?.url exists, open it in a new tab
    if (options?.url || schema?.url) {
      window.open(options?.url || schema?.url, "_blank");
      setIsAccepted(true);
      onChange(true);
    } else {
      // If there's no URL, open the modal as usual
      openModal();
    }
    e.preventDefault();
  };

  return (
    <>
      <div
        className="row w-max z-2"
        style={{ width: "fit-content" }}
        onClick={handleCheckboxClick} // Modified to use the new click handler
      >
        <div className="d-flex">
          <Checkbox
            id={id}
            className="m-0 p-0 me-2"
            checked={value || isAccepted}
            onClick={(e) => e.preventDefault()} // Prevent the default checkbox behavior
            onChange={(e) => e.preventDefault()} // Prevent the default checkbox change behavior
            required={required}
          />
          <div
            role="button"
            className="d-flex flex-row align-items-center gap-2"
          >
            <span className="fw-bold">{label}</span>{" "}
            {required && <span className="text-danger">*</span>}
          </div>
        </div>
      </div>

      <Modal
        title={label}
        centered
        open={isOpen}
        width={800}
        onCancel={() => {
          closeModal();
          setIsAccepted(undefined);
          onChange(undefined);
        }}
        footer={
          <Button
            type="primary"
            style={{ marginRight: "10px" }} // Adds space between buttons
            onClick={() => {
              closeModal();
              setIsAccepted(true);
              onChange(true);
            }}
          >
            Accept
          </Button>
        }
      >
        <div
          style={{
            padding: "20px",
            fontSize: "14px",
            lineHeight: "1.6",
            textAlign: "justify",
            height: "70vh",
            overflow: "auto",
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: options?.description || schema?.description,
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default TermsAndConditionWidget;
