import React from 'react';
import { Button } from 'antd';

const CustomButton = (props) => {
  const { id, uiSchema, schema, disabled, readonly, options } = props;
  const { onClick } = uiSchema?.['ui:options'] || {};
  const { show_loader } = options;

  const extractIndex = () => {
    try {
      const targetId = id || '';
      const idParts = targetId.match(/\d+/g);
      return idParts ? parseInt(idParts[idParts.length - 1]) : undefined;
    } catch (error) {
      console.error('Error extracting index:', error);
      return undefined;
    }
  };

  const index = extractIndex();
  const handleClick = () => {
    if (typeof onClick === 'function') {
      index !== undefined ? onClick(index) : onClick();
    }
  };

  const disabled_buttons = (options?.disabled_buttons ?? {});
  const isDisabled = Boolean(
    disabled || readonly || (index !== undefined && disabled_buttons[index])
  );

  return (
    <Button
      type='primary'
      htmlType='button'
      onClick={handleClick}
      disabled={isDisabled}
      loading={!!show_loader}
    >
      {schema.title}
    </Button>
  );
};

export default CustomButton;