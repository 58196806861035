import React, { useEffect, useState } from "react";
import { useAnonymous } from "../context/anonymousContext";

const BrandingWrapper = ({ children }) => {
  const { brandingScript } = useAnonymous();
  const [headerHtml, setHeaderHtml] = useState("");
  const [bannerHtml, setBannerHtml] = useState(""); // Added banner state
  const [footerHtml, setFooterHtml] = useState("");
  const [displayFlags, setDisplayFlags] = useState({
    header: true,
    footer: true,
    banner: true
  });

  useEffect(() => {
    const loadBrandingScript = async () => {
      try {
        if (brandingScript) {
          const script = document.createElement("script");
          script.textContent = brandingScript;
          document.body.appendChild(script);

          if (window.createBrandingElements) {
            const brandingElements = window.createBrandingElements();

            setHeaderHtml(brandingElements.headerHtml);
            setBannerHtml(brandingElements.bannerHtml); // Store banner HTML
            setFooterHtml(brandingElements.footerHtml);

            if (brandingElements.displayFlags) {
              setDisplayFlags(brandingElements.displayFlags);
            }
          } else {
            throw new Error(
              "Branding script did not define createBrandingElements"
            );
          }
        }
      } catch (error) {
        console.error("Error loading branding script:", error);
      }
    };

    loadBrandingScript();

    return () => {
      const loadedScript = document.body.querySelector("script");
      if (loadedScript) {
        document.body.removeChild(loadedScript);
      }
    };
  }, [brandingScript]);

  return (
    <>
      {displayFlags.header && (
        <div dangerouslySetInnerHTML={{ __html: headerHtml }} />
      )}
      {displayFlags.banner && (
        <div dangerouslySetInnerHTML={{ __html: bannerHtml }} />
      )}
      <main className="steps">{children}</main>
      {displayFlags.footer && (
        <div dangerouslySetInnerHTML={{ __html: footerHtml }} />
      )}
    </>
  );
};

export default BrandingWrapper;


// import React, { useEffect, useState } from "react";
// import { renderToString } from 'react-dom/server';
// import { FooterWidget, HeaderWidget, LeftSidebarWidget, RightSidebarWidget } from "../widgets/BrandingWidgets";

// const BrandingWrapper = ({ children }) => {

//   const [headerHtml, setHeaderHtml] = useState('');
//   const [footerHtml, setFooterHtml] = useState('');
//   const [leftSidebarHtml, setLeftSidebarHtml] = useState('');
//   const [rightSidebarHtml, setRightSidebarHtml] = useState('');
//   const [displayFlags, setDisplayFlags] = useState({
//     header: true,
//     footer: true,
//     leftSidebar: true,
//     rightSidebar: true
//   });

//   useEffect(() => {
//     const script = document.createElement("script");
//     script.src = `/branding.js`;
//     script.async = true;

//     script.onload = () => {
//       if (window.createBrandingElements) {
//         const widgets = {
//           header: () => renderToString(<HeaderWidget />),
//           footer: () => renderToString(<FooterWidget />),
//           leftSidebar: () => renderToString(<LeftSidebarWidget />),
//           rightSidebar: () => renderToString(<RightSidebarWidget />),
//         };

//         const { headerHtml, footerHtml, leftSidebarHtml, rightSidebarHtml, displayFlags: newDisplayFlags } = window.createBrandingElements(widgets);
//         setHeaderHtml(headerHtml);
//         setFooterHtml(footerHtml);
//         setLeftSidebarHtml(leftSidebarHtml);
//         setRightSidebarHtml(rightSidebarHtml);
//         setDisplayFlags(newDisplayFlags);
//       }
//     };

//     document.body.appendChild(script);

//     return () => {
//       if (document.body.contains(script)) {
//         document.body.removeChild(script);
//       }
//     };
//   }, []);

//   return (
//     <>
//       {displayFlags.header && <div dangerouslySetInnerHTML={{ __html: headerHtml }} />}
//       <div >
//         {displayFlags.leftSidebar && <div dangerouslySetInnerHTML={{ __html: leftSidebarHtml }} />}
//         <main className="steps">{children}</main>
//         {displayFlags.rightSidebar && <div dangerouslySetInnerHTML={{ __html: rightSidebarHtml }} />}
//       </div>
//       {displayFlags.footer && <div dangerouslySetInnerHTML={{ __html: footerHtml }} />}
//     </>
//   );
// };
// export default BrandingWrapper
