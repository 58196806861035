import React, { useState, useEffect, useRef, useMemo } from "react";
import Form from "@rjsf/antd";
import { customizeValidator } from "@rjsf/validator-ajv8";
import CustomSelectWidget from "../widgets/CustomSelectWidget";
import ajvErrors from "ajv-errors";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomDatePicker from "../widgets/CustomDatePicker";
import CustomFileUpload from "../widgets/CustomFileUpload";
import NepaliDatePicker from "../widgets/NepaliDatePicker";
import MapWidget from "../widgets/MapWidget";
import useFetchToken from "../hooks/useFetchToken";
import { Button, Card, Col, message, Row, Spin, Popconfirm, Flex } from "antd";
import { useAnonymous } from "../context/anonymousContext";
import apiClient from "../constants/apiHelper";
import axiosClient from "../constants/axiosInterceptor";
import { useFileUpload } from "../context/FileUploadContext";
import { adToBs, bsToAd } from "@sbmdkl/nepali-date-converter";
// import useCreateDraft from '../hooks/useCreateDraft';
import { useDraft } from "../context/DraftContext";
import moment from "moment";
import ObjectFieldTemplate from "../shared/CustomObjectFieldTemplate";
import CaptchaWidget from "../widgets/CaptchaWidget";
import ArrayWidget from "../widgets/ArrayWidget";
import TableWidgets from "../widgets/TableWidgets";
import CustomMultiSelectWidget from "../widgets/CustomMultiSelectWidget";
import CustomTextWidget from "../widgets/CustomTextWidget";
import useDebounce from "../hooks/useDebounce";
import CardWidget from "../widgets/CardWidget";
import CustomTableWidget from "../widgets/CustomTableWidget";
import PopupCheckboxWidget from "../widgets/PopupCheckboxWidget";
import { transformErrors } from "../constants/transformErrors";
import { Widgets } from "../utils/arrayHelper";
import ArrayFieldTemplate from "../shared/ArrayFieldTemplate";
import { ProgressBar } from "../widgets";
import { API_URL, BASE_URL } from "../constants/authConst";
import { calculateYearlyInterest } from "../utils/intrestCalculator";
import TableWidget from "../widgets/TableWidgets";
export const dynamicFormValidator = customizeValidator();
ajvErrors(dynamicFormValidator.ajv);
const DynamicFormGenerator = () => {
  const {
    anonymous,
    steps,
    setAnonymous,
    setSubmissionId,
    submissionId: contextSubmissionId,
    status,
    isAccountValidation,
    setStatus,
    setIsAccountValidation,
    setBrandingScript,
    brandingScript,
    currentStep,
    setCurrentStep,
    count,
    setCount,
    cardData,
    setLoading,
    loading,
    submissionId,
    setSteps,
  } = useAnonymous();
  const factoryRef = useRef(null);
  //console.log(cardData, "cardData");
  const [skipValidation, setSkipValidation] = useState(false);
  const [serverErrors, setServerErrors] = useState({});
  const { id: paramsId, stepslug, slug } = useParams();
  const [responseData, setResponseData] = useState(null);
  const token = sessionStorage.getItem("token");
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const [jsonSchema, setJsonSchema] = useState(null);
  const [uiSchema, setUiSchema] = useState(null);
  const previousFormData = useRef({});
  const location = useLocation();
  const [localFixedDepositAmount, setLocalFixedDepositAmount] = useState("");
  const [stepData, setStepData] = useState([]);
  const { state } = location;
  const { selectedCard } = location.state || {};
  const customerData = state?.customerData;
  const [errorState, setErrorState] = useState([]);
  //console.log(state, "help");
  const [liveValidate, setLiveValidate] = useState(false);

  const [pendingSubmit, setPendingSubmit] = useState(false);
  const { loading: tokenLoading, error: tokenError } = useFetchToken();

  const { fileNames } = useFileUpload();
  const [globalErrorMessage, setGlobalErrorMessage] = useState("");
  const [fetchedData, setFetchedData] = useState({});
  const [optionsData, setOptionsData] = useState({});

  const { createDraft, isCreatingDraft } = useDraft();

  const masterDataUrl = `${API_URL}/frontend//master-data`;
  const [factoryData, setFactoryData] = useState();

  //it comes form schema to rerender the form
  const [schemaKeys, setSchemaKeys] = useState([]);

  const eachStepDataUrl = `/forms/submissions/${stepslug}/${paramsId}`;
  const allDataUrl = `/forms/submissions/${paramsId}`;
  // const formKey = `${formData.dateOfBirthAD}-${formData.dateOfBirthBS}-
  // -${formData.panNoIssuedDateBS}-${formData.panNoIssuedDateAD}-
  // -${masterDataUrl}-${formData.idateOfExpiryAD}-${formData.idateOfExpiryBS}
  // -${formData.issuedDateBS}-${formData.issuedDateAD}
  // -${formData.dateOfIssuanceBS}-${formData.dateOfExpiryBS}
  // -${formData.dateOfIssuanceAD}-${formData.dateOfExpiryAD}-${formData.permanentMap}`;
  useEffect(() => {
    if (jsonSchema && jsonSchema.keys) {
      setSchemaKeys(jsonSchema.keys); // Ensure `jsonSchema.keys` is valid
    } else {
      setSchemaKeys([]); // Fallback to an empty array
    }
  }, [jsonSchema]);

  // useEffect(() => {
  //   if (jsonSchema && jsonSchema.properties) {
  //     setSchemaKeys(jsonSchema.keys);
  //   }
  // }, [jsonSchema]);

  const formKey = useMemo(() => {
    // Safeguard: Default schemaKeys to an empty array
    const validSchemaKeys = Array.isArray(schemaKeys) ? schemaKeys : [];

    if (!formData || validSchemaKeys.length === 0) return "";

    // Create formKey using schemaKeys and formData values
    const keyValues = validSchemaKeys
      .map((key) => formData[key.replaceAll("formData.", "")] || "")
      .join("-");
    return `form-${keyValues}`;
  }, [schemaKeys, formData]);

  // //console.log(formKey, "hell");
  const memoizedOptionsData = useMemo(() => optionsData, [optionsData]);

  useEffect(() => {
    const initializeFactory = async () => {
      if (tokenLoading) return;

      try {
        // Fetch JSON schema
        const response = await fetch(`${BASE_URL}/forms/${slug}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) throw new Error("Failed to fetch form schema");

        const data = await response.json();

        const progressBarSteps = data?.data?.steps.map(
          (step) => step.step_slug
        );
        const stepSlugs = data?.data?.steps.map((step) => step.step_slug);
        setSteps(stepSlugs);
        //console.log(steps, "dd");

        setAnonymous(data.data.anonymous === 1);

        if (data.data.branding_script) {
          setBrandingScript(data.data.branding_script);
        }
        setStepData(data.data.steps);
        const stepData = data.data.steps?.find(
          (item) => stepslug === item?.step_slug
        );
        setResponseData(stepData);
        const jsonSchemaData = JSON.parse(stepData.form_schema);

        // Execute UI schema script
        if (stepData.ui_schema) {
          const executeScript = new Function(stepData.ui_schema);
          executeScript();
        }

        // Initialize UI Schema Factory
        if (window.UISchemaFactory) {
          factoryRef.current = new window.UISchemaFactory(masterDataUrl, {
            token,
            axiosClient,
            apiClient,
            setJsonSchema,
            moment,
            anonymous,
            adToBs,
            bsToAd,
            status,
            paramsId,
            setCount,
            count,
            formData,
            setFormData,
            setFetchedData,
            fetchedData,
            stepslug,
            slug,
            allDataUrl,
            eachStepDataUrl,
            optionsData: memoizedOptionsData,
            setOptionsData,
            navigate,
            setLoading,
            Button,
            Popconfirm,
            steps,
            message,
            calculateYearlyInterest,
          });
       
          const uiSchemaData = factoryRef.current.createUISchema({
            formData: formData,
            setFormData,
            jsonSchema: jsonSchemaData,

            widgets: {
              CustomSelectWidget,
              TableWidgets,
              ArrayWidget,
              MapWidget,
              CustomDatePicker,
              CustomFileUpload,
              NepaliDatePicker,
              CaptchaWidget,
              CustomMultiSelectWidget,
              CardWidget,
              CustomTableWidget,
              PopupCheckboxWidget,
              CustomTextWidget,
            },
            setJsonSchema,
            ObjectFieldTemplate,
          });
          // setFactoryData(factory);
          setJsonSchema(jsonSchemaData);
          setUiSchema(uiSchemaData);
        } else {
          console.error("UISchemaFactory is not available");
        }
      } catch (error) {
        console.error("Error initializing factory:", error);
      }
    };

    initializeFactory();
  }, [token, tokenLoading, stepslug, slug, currentStep]);
  //console.log(formData, "factory");

  useEffect(() => {
    setIsAccountValidation(fetchedData?.include_account_validation === "Yes");
    if (stepslug === "review") {
      setStatus("submitted");
    }
    if (paramsId) {
      setSubmissionId(paramsId);
    }
    //console.log(status, stepslug, "status");
  }, [fetchedData, setIsAccountValidation, stepslug, paramsId]);

  // useEffect(() => {
  //   if (fetchedData?.is_anonymous === 0) {
  //     const isVerified = sessionStorage.getItem("verified");

  //     if (isVerified) {
  //       navigate(`/services/${slug}/${stepslug}/${paramsId}`);
  //     } else {
  //       navigate(
  //         `/services/${slug}/${stepslug}/otp-form/${fetchedData?.request_id}`
  //       );
  //       message.success("Check your Email for OTP");
  //     }
  //   }
  // }, [fetchedData, slug, stepslug, paramsId, navigate]);

  useEffect(() => {
    if (!jsonSchema || !uiSchema) return; // Wait for factory initialization

    // Determine initial form data
    let initialFormData = {};

    // Use fetched data if available
    if (fetchedData && Object.keys(fetchedData).length > 0) {
      initialFormData = fetchedData?.form_data?.formData || {};
      setFormData(initialFormData);
      //console.log(initialFormData, "initialFormData1");
    }
    // Use customer data as a fallback
    else if (customerData) {
      initialFormData = {
        accountNumber: customerData?.customerDetail?.account_number || "",
        mobileNumber: customerData?.customerDetail?.mobile_number,
        emailAddress: customerData?.customerDetail?.email_address || "",

        preferredBranch: customerData?.customerDetail?.branch_id,

        accountName: customerData?.customerDetail?.account_name,
      };
      setFormData({ ...formData, ...initialFormData });
      // sessionStorage.setItem("productId", customerData?.customerDetail?.fd_product_manager_id,)
      //console.log(initialFormData, "initialFormData2");
    }

    // // Add product type if selectedCard is present
    // if (selectedCard) {
    //   initialFormData.productName = selectedCard?.title;
    //   initialFormData.interestRate = selectedCard?.interestRateTenure?.interestRateTenure?.[0]?.interestRate;

    //   setFormData({ ...formData, ...initialFormData });
    //   //console.log(initialFormData, "initialFormData3");
    // }

    //console.log(initialFormData, "initialFormData");

    // }, [fetchedData, customerData, selectedCard,jsonSchema]);
  }, [customerData, fetchedData, jsonSchema]);

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };

  const onChange = ({ formData, id }) => {
    setFormData(formData);
    // Calculate yearly interest amount
    if (factoryRef.current) {
      factoryRef.current.updateFormAndSchema(
        formData,
        responseData?.formCondition
      );
    }
    // const yearlyInterest = calculateYearlyInterest(
    //   formData.fixedDepositAmount,
    //   formData.interestRate
    // );

    // // Update the form data, including the calculated yearly interest amount
    // setFormData((prevData) => ({
    //   ...prevData,
    //   ...formData,
    //   yearlyInterestAmount: yearlyInterest, // Add the calculated interest amount
    // }));

    //Return the updated formData
    // return {
    //   ...formData,
    //   yearlyInterestAmount: yearlyInterest,
    // };
  };

  // console.log(formData, "front");
  const debouncedOnChange = useDebounce(onChange, 500);
  // const fdAmountOnChange = (event) => {
  //   const value = event.target.value;
  //   setLocalFixedDepositAmount(value); // Update local state immediately
  //   debouncedSetFormData(value); // Update formData after debounce
  // };

  // const debouncedSetFormData = debounce((value) => {
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     fixedDepositAmount: value,
  //   }));
  // }, 300);
  const handleFormSubmission = async (formData, submissionType) => {
    setLoading(true);
    const { ppSizePhoto, citizenship } = fileNames;
    const endpoint = `${BASE_URL}/forms/submit`;
    const sessionNumber = sessionStorage.getItem("session_number");
    // Check if submissionId is available from params, context, or customerData
    let submissionId =
      paramsId || contextSubmissionId || customerData?.request_id;

    // If submissionId is not available, and the user is submitting, create a draft first
    if (!submissionId) {
      try {
        submissionId = await createDraft(slug, stepslug, token);
        if (!submissionId) {
          message.error("Failed to create draft. Please try again.");
          return;
        }
        setSubmissionId(submissionId); // Save the submissionId in the context
      } catch (error) {
        console.error("Error creating draft:", error);
        message.error("Failed to create draft. Please try again.");
        return;
      }
    }

    try {
      // Proceed with form submission
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          form_slug: slug,
          step_slug: stepslug,
          request_id: submissionId,
          submission_type: submissionType,
          sessionNumber: customerData?.customerDetail?.session_number,
          form_data: {
            formData,
          },
        }),
      });

      const result = await response.json();
      if (!response.ok) {
        handleServerErrors(result);
      } else {
        // Handle navigation based on submission type
        if (submissionType === "draft") {
          // For draft, navigate to the success page
          sessionStorage.setItem("currentStep", 0); // Reset step to 0
          navigateToSuccessPage(submissionId, "draft");
          setLoading(false);
        } else if (submissionType === "submitted") {
          // For submit, navigate to the next step if available
          if (!jsonSchema.singleForm) {
            if (result.next_step) {
              setCurrentStep((prevStep) => prevStep + 1);
              navigate(
                `/services/${slug}/${result.next_step}/${submissionId}`,
                {
                  state: {
                    id: result.uuid,
                    productId:
                      customerData?.customerDetail?.fd_product_manager_id,
                  }, // Pass the uuid if needed
                }
              );
              setLoading(false);
            } else {
              // If no next step, navigate to the success page
              sessionStorage.setItem("currentStep", 0);
              navigateToSuccessPage(submissionId, "submitted");
              setLoading(false);
            }
          } else {
            navigateToSuccessPage(submissionId, "submitted");
            setLoading(false);
          }
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      message.error("Submission failed. Please try again.");
      setLoading(false);
    }
  };

  const onSubmit = async ({ formData }) => {
    await handleFormSubmission(formData, "submitted");
    setFormData({});
    setLiveValidate(false);
  };

  const customValidate = (formData, errors) => {
    //console.log("called");
    if (skipValidation) {
      return {};
    }

    const enteredAmountField = "fixedDepositAmount";

    // Ensure the field exists in the formData
    const fixedDepositAmount = parseFloat(formData[enteredAmountField]); // Convert to number
    const minimumBalance = parseFloat(selectedCard?.minimumBalance); // Convert to number

    if (isNaN(fixedDepositAmount)) {
    } else if (fixedDepositAmount < minimumBalance) {
      // message.error( `The amount must be at least ${selectedCard.minimumBalance}.`)
      errors[enteredAmountField].addError(
        `The amount must be at least ${minimumBalance}.`
      );
    }

    Object.entries(serverErrors).forEach(([field, fieldErrors]) => {
      if (Array.isArray(fieldErrors)) {
        fieldErrors.forEach((item, index) => {
          if (errors[field] && errors[field][index]) {
            Object.entries(item).forEach(([subField, error]) => {
              if (errors[field][index][subField]) {
                errors[field][index][subField].addError(error);
              }
            });
          }
        });
      } else if (typeof fieldErrors === "object" && fieldErrors !== null) {
        if (
          Object.values(fieldErrors).every((val) => typeof val === "object")
        ) {
          Object.entries(fieldErrors).forEach(([index, indexErrors]) => {
            if (errors[field] && errors[field][index]) {
              Object.entries(indexErrors).forEach(([subField, error]) => {
                if (errors[field][index][subField]) {
                  errors[field][index][subField].addError(error);
                }
              });
            }
          });
        } else {
          if (errors[field]) {
            errors[field].addError(fieldErrors);
          }
        }
      } else {
        if (errors[field]) {
          errors[field].addError(fieldErrors.toString());
        }
      }
    });

    return errors;
  };

  const handleSubmit = async (submissionType) => {
    // Skip validation only if saving as draft
    if (submissionType === "draft") {
      setSkipValidation(true); // Skip validation for draft
    } else {
      setSkipValidation(false); // Ensure validation is done for submissions
    }

    // Trigger form submission manually for non-drafts
    if (submissionType === "submitted") {
      const form = document.querySelector("form");
      if (form) {
        form.dispatchEvent(
          new Event("submit", { cancelable: true, bubbles: true })
        );
      }
    } else {
      // Directly save as draft
      await handleFormSubmission(formData, "draft");
    }
  };

  const handleServerErrors = (errorData) => {
    if (Array.isArray(errorData) && errorData.length > 0) {
      errorData = errorData[0];
    }

    if (errorData.globalErrorMessage) {
      message.error(errorData.globalErrorMessage);
      setGlobalErrorMessage(errorData.globalErrorMessage);
    }

    if (errorData.fieldLevelErrors) {
      const newErrors = {};
      Object.entries(errorData.fieldLevelErrors).forEach(([field, errors]) => {
        if (Array.isArray(errors)) {
          newErrors[field] = errors.map((item) =>
            Object.entries(item).reduce((acc, [subField, subErrors]) => {
              acc[subField] = Object.values(subErrors).join(", ");
              return acc;
            }, {})
          );
        } else if (typeof errors === "object" && errors !== null) {
          if (Object.values(errors).every((val) => typeof val === "object")) {
            newErrors[field] = Object.entries(errors).reduce(
              (acc, [index, indexErrors]) => {
                acc[index] = Object.entries(indexErrors).reduce(
                  (subAcc, [subField, subErrors]) => {
                    subAcc[subField] = Object.values(subErrors).join(", ");
                    return subAcc;
                  },
                  {}
                );
                return acc;
              },
              {}
            );
          } else {
            newErrors[field] = Object.values(errors).join(", ");
          }
        } else {
          newErrors[field] = errors.toString();
        }
      });
      setServerErrors(newErrors);
    }
    if (errorData.error) {
      message.error(errorData.error);
      return;
    }
  };

  const navigateToSuccessPage = (id, submissionType) => {
    const draftUrl = `https://bpm-form.server247.info/services/${slug}/${stepslug}/${id}`;
    navigate(`/services/${slug}/${stepslug}/success-page`, {
      state: { id: id, draftUrl, submissionType },
    });
  };

  if (!jsonSchema || !uiSchema) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin size="large" spinning={true} />
      </div>
    );
  }
  const handleCardSubmit = async (url, requestBody) => {
    //console.log("Called", url);

    try {
      const token = sessionStorage.getItem("token");

      if (!token) {
        console.error("Token is missing!");
        return;
      }

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      };
      setLoading(true);
      // Make the fetch request
      const response = await fetch(url, options);

      // Parse the response body as JSON
      const result = await response.json();
      //console.log(result, "happy");
      if (!response.ok) {
        const errorMessage = result?.error || "An unknown error occurred.";
        message.error(errorMessage);
        throw new Error(`Error: ${response.statusText}`);
      }
      if (response.ok && result.success) {
        message.success(result?.message || "Form submitted successfully!");
        setLiveValidate(false);
        navigate(
          `/services/${slug}/${result.next_step}/${requestBody?.request_id}`,
          {
            state: {
              customerData: result,
              requestId: requestBody?.request_id,
              isNewRequest: true,
            },
          }
        );
      } else {
        message.error(result.error || "Failed to submit form.");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      // You can handle errors here, like showing an error message
      // message.error("An error occurred while submitting the form.");
    } finally {
      setLoading(false);
    }
  };
  const handleCardClick = async (url) => {
    if (loading) return;
    setLiveValidate(true);

    let draftId;
    try {
      if (!submissionId && !paramsId) {
        draftId = await createDraft(slug, stepslug, token);
        setSubmissionId(draftId);
      }

      const requestBody = {};

      if (Array.isArray(jsonSchema.requestBodyKey)) {
        jsonSchema.requestBodyKey.forEach((item) => {
          Object.entries(item).forEach(([key, variableName]) => {
            if (key === "request_id") {
              // Assign draftId or submissionId to request_id
              requestBody[key] = submissionId || draftId;
            } else {
              try {
                // Use eval to dynamically fetch the value, but ensure it is safe
                const value = eval(variableName);
                if (value !== undefined && value !== null) {
                  requestBody[key] = value; // Assign other values dynamically
                }
              } catch (error) {
                console.warn(`Unable to resolve variable ${variableName}`);
              }
            }
          });
        });
      }

      //console.log(requestBody, "Payload for actual API");

      // Check if all keys in requestBody have values
      const hasEmptyValue = Object.values(requestBody).some(
        (value) => value === undefined || value === null || value === ""
      );

      if (hasEmptyValue) {
        console.warn(
          "Request body contains empty values. API call will not be made."
        );
        message.warning(
          "Please fill in all required fields before proceeding."
        );
        return;
      }

      handleCardSubmit(`${BASE_URL}${url}`, requestBody);
      //console.log("API submitted successfully!");
    } catch (error) {
      console.error("Error occurred:", error);
      setLoading(false);
    }
  };

  // filter those steps which has ProressBar true in form schema
  const stepsWithProgressBar = stepData
    .filter((item) => {
      try {
        // Parse the form_schema JSON
        const schema = JSON.parse(item.form_schema);
        return schema.progressBar === true; // Check if progressBar is true
      } catch (error) {
        console.error("Invalid JSON in form_schema:", error);
        return false; // Exclude invalid JSON schemas
      }
    })
    .map((item) => item.step_slug);

  console.log(stepsWithProgressBar, "hell"); // This will output the step slugs with progressBar: true

  return (
    <>
      {jsonSchema ? (
        jsonSchema.model ? (
          // Render in a card if jsonSchema.model is true
          <div>
            <Flex justify="center" wrap="wrap">
              <Card
                style={{ maxWidth: "600px", width: "100%" }}
                bordered
                title={jsonSchema?.title}
              >
                <Form
                  key={`step-${currentStep}-${formKey}`}
                  schema={jsonSchema}
                  templates={{
                    ObjectFieldTemplate,
                    ArrayFieldTemplate,
                  }}
                  uiSchema={uiSchema}
                  disabled={status}
                  formData={formData}
                  onChange={debouncedOnChange}
                  onSubmit={onSubmit}
                  liveValidate={liveValidate}
                  customValidate={customValidate}
                  validator={dynamicFormValidator}
                  transformErrors={transformErrors}
                  noHtml5Validate
                  showErrorList={false}
                  widgets={Widgets}
                  formContext={{
                    descriptionLocation: "tooltip",
                    formData,
                    errors: errorState,
                    serverErrors,
                    liveValidate,
                  }}
                >
                  <div style={{ marginTop: "16px" }}>
                    <Row
                      justify={
                        jsonSchema?.buttons?.length === 1
                          ? "end"
                          : "space-between"
                      }
                      className="d-flex gap-2"
                    >
                      {jsonSchema?.buttons &&
                        jsonSchema.buttons.map((button, index) => (
                          <Col key={index}>
                            <Button
                              type={button.type}
                              onClick={() => handleCardClick(button.url)}
                              loading={loading}
                            >
                              {button.name}
                            </Button>
                          </Col>
                        ))}
                    </Row>
                  </div>
                </Form>
              </Card>
              {jsonSchema.showTable ? (
                <Card>
                  <TableWidget schema={jsonSchema} />
                </Card>
              ) : null}
            </Flex>
          </div>
        ) : (
          // Render normally if jsonSchema.model is false

          <div className="container">
            {jsonSchema?.progressBar ? (
              <ProgressBar steps={stepsWithProgressBar} />
            ) : (
              <></>
            )}

            <Form
              key={`step-${currentStep}-${formKey}`}
              schema={jsonSchema}
              templates={{
                ObjectFieldTemplate,
                ArrayFieldTemplate,
              }}
              uiSchema={uiSchema}
              disabled={status}
              formData={formData}
              onChange={debouncedOnChange}
              onSubmit={onSubmit}
              liveValidate={liveValidate}
              customValidate={customValidate}
              validator={dynamicFormValidator}
              transformErrors={(errors) => transformErrors(errors)}
              noHtml5Validate
              showErrorList={false}
              widgets={Widgets}
              formContext={{
                descriptionLocation: "tooltip",
                formData,
                errors: errorState,
                serverErrors,
                liveValidate,
              }}
            >
              <div style={{ marginTop: "16px" }}>
                <Row
                  justify={
                    !jsonSchema?.showSaveandDraft ? "end" : "space-between"
                  }
                  className="d-flex gap-2"
                >
                  {!jsonSchema && (
                    <Col>
                      <Button
                        type="default"
                        onClick={() => handleSubmit("draft")}
                        disabled={loading}
                        hidden={
                          jsonSchema?.submissionHidden ||
                          jsonSchema?.showSaveandDraft
                        }
                      >
                        Save as Draft
                      </Button>
                    </Col>
                  )}
                  <Col>
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={() => setLiveValidate(true)}
                      hidden={jsonSchema?.submissionHidden}
                      loading={loading}
                      style={{
                        opacity: 1, // slightly fade when loading
                      }}
                    >
                      Save and Continue
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        )
      ) : (
        // Render a loading spinner while jsonSchema is null
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spin size="large" spinning={true} />
        </div>
      )}
    </>
  );
};

export default DynamicFormGenerator;
