import React from 'react';
import { Typography, Modal } from 'antd';

const { Text } = Typography;

const NavigationText = (props) => {
    const { label } = props;
    
    const handleNavigation = () => {
        Modal.confirm({
            title: 'Confirmation',
            content: 'Are you sure you want to navigate to the account opening page?',
            onOk: () => {
                window.location.href = 'https://online.manjushreefinance.com.np/';
            },
        });
    };

    return (
        <Text>
            {label.split('click here').map((part, index, array) => (
                <React.Fragment key={index}>
                    {part}
                    {index !== array.length - 1 && (
                        <Text style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={handleNavigation}
                        >
                            click here
                        </Text>
                    )}
                </React.Fragment>
            ))}
        </Text>
    );
};

export default NavigationText;
