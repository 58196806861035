import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const CaptchaWidget = ({ value, onChange }) => {
  const handleCaptchaChange = (captchaValue) => {
    onChange(captchaValue); // Pass the value to form
  };

  return (
    <div>
      <ReCAPTCHA
        sitekey="6Lc6fcAqAAAAAGoL-xZ8vIwQG7NGQI1CDeYtvEWx"
        onChange={handleCaptchaChange}
      />
    </div>
  );
};

export default CaptchaWidget;
